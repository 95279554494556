import React, { useContext } from "react";
import { Route, Navigate, Routes, useLocation } from "react-router-dom";

import { AuthContext } from "./AuthContext";
import LoginPage from "../pages/LoginPage";
import LandingPage from "../pages/LandingPage";
import HomePage from "../pages/HomePage";
import BlocksPage from "../pages/BlocksPage";
import AgentPage from "../pages/AgentPage";
import AgentsPage from "../pages/AgentsPage";
import TasksPage from "../pages/TasksPage";
import AppsPage from "../pages/AppsPage";
import NotFoundPage from "../pages/NotFound";
import ProfilePage from "../pages/ProfilePage";
import OverviewPage from "../pages/docs/OverviewPage";
import GettingStartedPage from "../pages/docs/GettingStartedPage";
import AppOverviewPage from "../pages/docs/AppOverviewPage";
import CLILoginPage from "../pages/CLILogin";
import AgentDocsPage from "../pages/docs/AgentsDocsPage";
import AgentOverviewPage from "../pages/docs/AgentOverviewPage";
import ToolOverviewPage from "../pages/docs/ToolOverviewPage";
import ToolsPage from "../pages/ToolsPage";
import RuntimesPage from "../pages/RuntimesPage";
import HelpPage from "../pages/HelpPage";

export default function AppRoutes() {
  const location = useLocation();

  const { currentUser, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  // const redirectPath = location.state?.from?.pathname || "/home";

  return (
    <Routes>
      <Route path="/" element={currentUser ? <HomePage /> : <LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/home"
        element={
          currentUser ? (
            <HomePage />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          )
        }
      />
      <Route
        path="/blocks"
        element={
          currentUser ? (
            <BlocksPage />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          )
        }
      />
      <Route
        path="/agents"
        element={
          currentUser ? (
            <AgentsPage />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          )
        }
      />
      <Route
        path="/agents/:name"
        element={
          currentUser ? (
            <AgentPage />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          )
        }
      />
      <Route
        path="/tasks"
        element={
          currentUser ? (
            <TasksPage />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          )
        }
      />
      <Route
        path="/apps"
        element={
          currentUser ? (
            <AppsPage />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          )
        }
      />
      <Route
        path="/tools"
        element={
          currentUser ? (
            <ToolsPage />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          )
        }
      />
      <Route
        path="/runtimes"
        element={
          currentUser ? (
            <RuntimesPage />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          )
        }
      />
      <Route
        path="/profile"
        element={
          currentUser ? (
            <ProfilePage />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          )
        }
      />
      <Route
        path="/cli-login"
        element={
          currentUser ? (
            <CLILoginPage />
          ) : (
            <Navigate to="/login" state={{ from: location }} />
          )
        }
      />
      <Route path="/help" element={<HelpPage />} />
      <Route path="/docs" element={<OverviewPage />} />
      <Route path="/docs/overview" element={<OverviewPage />} />
      <Route path="/docs/getting-started" element={<GettingStartedPage />} />
      <Route path="/docs/apps/overview" element={<AppOverviewPage />} />
      <Route path="/docs/agents" element={<AgentDocsPage />} />
      <Route path="/docs/agents/build" element={<AgentDocsPage />} />
      <Route path="/docs/tools/overview" element={<ToolOverviewPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
