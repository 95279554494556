// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getAuth,
  GoogleAuthProvider,
  inMemoryPersistence,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { isProd } from "../api/Env";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig;

if (isProd()) {
  firebaseConfig = {
    apiKey: "AIzaSyC7QwFSmLUUZWG-tu-9j3jM6WTEsaFMeL4",
    authDomain: "agentsea-prod.firebaseapp.com",
    projectId: "agentsea-prod",
    storageBucket: "agentsea-prod.appspot.com",
    messagingSenderId: "938810347762",
    appId: "1:938810347762:web:0c53ae3a159dbfce02006b",
    measurementId: "G-QBLMS54T60",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyBgyZqwWV5hzEuZkHChCy2FBTleUaUVIVE",
    authDomain: "kentauros-dev.firebaseapp.com",
    projectId: "kentauros-dev",
    storageBucket: "kentauros-dev.appspot.com",
    messagingSenderId: "412353088540",
    appId: "1:412353088540:web:8180d26d023975922723d9",
    measurementId: "G-KZR5747CE6",
  };
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

// auth.setPersistence(auth, inMemoryPersistence);  # TODO: bug here

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistence set to local");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });
